import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		showLeft: true,
		loading: false,
		// 用户对应的账号列表
		orgInfoList: [],
		selectOrg: {},
		OrgId: '',

		// 当前的用户信息
		userInfo:{}


	},
	getters: {
		getShowLeft(state){
			return state.showLeft
		},
		getLoading(state){
			return state.loading
		},
		//获取公司ID
		getOrgId(state){
			return state.OrgId
		},
		// 获取公司相关的信息
		getOrg(state){
			return {
				orgInfoList: state.orgInfoList,
				selectOrg: state.selectOrg,
			}
		},
		//
		orgInfoList(state){
			return state.orgInfoList
		},

		// 获取用户信息
		getUserInfo(state){
			return state.userInfo
		},
		getUserName(state){
			return state.userInfo?.userName || ''
		}
	},


	mutations: {
		updateState(state, option){
			for(let i in option){
				state[i] = option[i]
			}
		},
		upLoading(state, option){
			state.loading = option
		},
		upShowLeft(state, option){
			state.showLeft = option
		}
	},
	actions: {
		setState({ commit }, option) {
			commit('updateState', option)
		},
		setLoading({ commit }, option){
			commit('upLoading', option)
		},
		setShowLeft({ commit }, option){
			commit('upShowLeft', option)
		},
		//commit
	},
});

export default store;
