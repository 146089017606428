import Vue from 'vue'
import App from './App.vue'

import vanDesgin from '@/config/van.desgin.js'
import router from '@/router/index.js'
import store from '@/store/index.js'
import '@/assets/less/common.less'

Vue.config.productionTip = false

vanDesgin(Vue)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
