import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)


const router = new VueRouter({
    // mode: 'history',
    routes:[{
      path: '/',
      redirect: '/signTpl'
    },{
      path: '/viewTpl',
      name: 'viewTpl',
      des: '预览模版',
      meta:{
        title: '预览模版'
      },
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/viewTpl.vue'),
    },{
      path: '/signTpl',
      name: 'signTpl',
      des: '签署',
      meta:{
        title: ''
      },
      component: () =>import(/* webpackChunkName: "purchasingMain" */ '@/views/signTpl.vue'),
    }],
})







// let routerLength = 0 //vue 路由历史长度
// let showClosed = false //app是否已显示关闭按钮
// let lastHistoryLength = 0 //history 长度缓存
// router.afterEach((to, from)=>{
//   NProgress.done()
//   if(!lastHistoryLength){
//     routerLength += 1
//   } else {
//     if(history.length > lastHistoryLength){
//       routerLength += 1
//     }
//   }
//   lastHistoryLength = history.length
//   //判断历史路由长度并显示app关闭按钮
//   if(!showClosed && routerLength > 1){
//     JSBridge && JSBridge.setClose(true)
//     showClosed = true
//   }
// })



// router.beforeResolve((to, from, next) => {
//   // console.log(to,form)
//   console.log(to.query)
//   to.query.oid = 123
// 	next({
//     query:{
//       a: 123
//     }
//   })
// })


router.beforeEach((to, from , next) => {
  let { title } = to?.meta || {}
  document.title = title || ''
  // let path = to.path
  // to.query
  // to.query.detailid = 'name';
  // console.log(to.query)
 
  // // if(path != '/home' && oid){
  
  // // }
  // if(to.path == from.path){
  //   next()
  // }else{
  //   next(to)
  // }
  next()
});

export default router